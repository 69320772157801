import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { X, Heart, ShoppingCart, User, Box, Sliders } from 'lucide-react';
import Papa from 'papaparse';
import SkinTypeChecker from './SkinTypeChecker';

const ProductFinder = () => {
  const [step, setStep] = useState(0);
  const [selectedSkinTypes, setSelectedSkinTypes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allSkinTypes, setAllSkinTypes] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [showWishlist, setShowWishlist] = useState(false);
  const [showSkinTypeChecker, setShowSkinTypeChecker] = useState(false);
  const listRef = useRef(null);
  const containerRef = useRef(null);
  const resizeObserverRef = useRef(null);

  const sendHeightToParent = useCallback(() => {
    if (containerRef.current) {
      const height = containerRef.current.offsetHeight;
      window.parent.postMessage({ type: 'setHeight', height }, '*');
    }
  }, []);

  useEffect(() => {
    sendHeightToParent();

    // ResizeObserver setup
    resizeObserverRef.current = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          sendHeightToParent();
        }
      }
    });

    if (containerRef.current) {
      resizeObserverRef.current.observe(containerRef.current);
    }

    // Cleanup function
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [sendHeightToParent]);

  // Trigger height update after state changes that might affect layout
  useEffect(() => {
    sendHeightToParent();
  }, [step, showWishlist, products, sendHeightToParent]);

  // Trigger height update after DOM updates
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sendHeightToParent();
    }, 0);
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    const savedState = localStorage.getItem('productFinderState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setStep(parsedState.step);
      setSelectedSkinTypes(parsedState.selectedSkinTypes);
      setSelectedCategories(parsedState.selectedCategories);
      setSelectedAttributes(parsedState.selectedAttributes);
      setWishlist(parsedState.wishlist);
      setShowWishlist(parsedState.showWishlist);
    }
    document.title = "Juui Produktfinder";
  }, []);

  useEffect(() => {
    const stateToSave = {
      step,
      selectedSkinTypes,
      selectedCategories,
      selectedAttributes,
      wishlist,
      showWishlist
    };
    localStorage.setItem('productFinderState', JSON.stringify(stateToSave));
  }, [step, selectedSkinTypes, selectedCategories, selectedAttributes, wishlist, showWishlist]);

  const extractAttributes = useCallback((description) => {
    const attributeList = [
      "Feuchtigkeitsspendend", "Beruhigend", "Aufhellend", "Straffend", "Reinigend", 
      "Klärend", "Anti-Aging", "Porenverfeinernd", "Entzündungshemmend", "Nährend", 
      "Regenerierend", "Ausgleichend", "Glättend", "Schützend"
    ];
    return attributeList.filter(attr => description.toLowerCase().includes(attr.toLowerCase()));
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [categoriesResponse, skinTypesResponse, productsResponse] = await Promise.all([
          fetch('/kategorien.txt'),
          fetch('/hauttypen.txt'),
          fetch('/data.csv')
        ]);
        
        if (!categoriesResponse.ok || !skinTypesResponse.ok || !productsResponse.ok) {
          throw new Error(`HTTP error! status: ${categoriesResponse.status}, ${skinTypesResponse.status}, ${productsResponse.status}`);
        }
        
        const categoriesText = await categoriesResponse.text();
        const categories = categoriesText.split('\n').filter(Boolean).map(cat => cat.trim());
        setAllCategories(categories);
        
        const skinTypesText = await skinTypesResponse.text();
        const skinTypes = skinTypesText.split('\n').filter(Boolean).map(type => type.trim());
        setAllSkinTypes(skinTypes);
        
        const productsText = await productsResponse.text();
        const results = Papa.parse(productsText, { header: true });
        
        const parsedProducts = results.data
          .filter(row => row.Produktbezeichnung && row.Hauttyp && row.product_type && parseInt(row.Bestand) > 0)
          .map(row => ({
            name: row.Produktbezeichnung,
            skinTypes: row.Hauttyp ? row.Hauttyp.split(',').map(type => type.trim()) : [],
            categories: row.product_type ? row.product_type.split(',').map(cat => cat.trim()) : [],
            attributes: extractAttributes(row.Produktbeschreibung || ''),
            description: row.Produktbeschreibung || '',
            price: row.Verkaufspreis || '',
            image: row.image_link || '',
            brand: row.brand || '',
            url: row.URL || '',
            id: row.ID || '',
            stock: parseInt(row.Bestand) || 0
          }));
        
        setProducts(parsedProducts);
        setLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    loadData();
  }, [extractAttributes]);

  const filteredProducts = useMemo(() => {
    return products.filter(product => 
      (selectedSkinTypes.length === 0 || product.skinTypes.some(type => selectedSkinTypes.includes(type))) &&
      (selectedCategories.length === 0 || selectedCategories.some(cat => product.categories.includes(cat))) &&
      (selectedAttributes.length === 0 || selectedAttributes.every(attr => product.attributes.includes(attr)))
    );
  }, [products, selectedSkinTypes, selectedCategories, selectedAttributes]);

  const availableCategories = useMemo(() => {
    if (selectedSkinTypes.length === 0) {
      return allCategories;
    }
    const relevantProducts = products.filter(product => 
      selectedSkinTypes.some(type => product.skinTypes.includes(type))
    );
    return [...new Set(relevantProducts.flatMap(product => product.categories))];
  }, [products, selectedSkinTypes, allCategories]);

  const availableAttributes = useMemo(() => {
    let relevantProducts = products;
    
    if (selectedSkinTypes.length > 0) {
      relevantProducts = relevantProducts.filter(product => 
        selectedSkinTypes.some(type => product.skinTypes.includes(type))
      );
    }
    
    if (selectedCategories.length > 0) {
      relevantProducts = relevantProducts.filter(product => 
        selectedCategories.some(cat => product.categories.includes(cat))
      );
    }
    
    if (selectedAttributes.length > 0) {
      relevantProducts = relevantProducts.filter(product => 
        selectedAttributes.every(attr => product.attributes.includes(attr))
      );
    }
    
    const remainingAttributes = [...new Set(relevantProducts.flatMap(p => p.attributes))];
    return remainingAttributes.filter(attr => {
      const productsWithAttr = relevantProducts.filter(p => p.attributes.includes(attr));
      return productsWithAttr.some(p => 
        selectedAttributes.every(selectedAttr => p.attributes.includes(selectedAttr))
      );
    });
  }, [products, selectedSkinTypes, selectedCategories, selectedAttributes]);

  const getFirstTwoSentences = (text) => {
    const sentences = text.match(/[^\.!\?]+[\.!\?]+/g);
    if (sentences && sentences.length >= 2) {
      return `${sentences[0]}<br /><br />${sentences[1]}`;
    }
    return sentences ? sentences[0] : '';
  };

  const resetSelection = () => {
    setSelectedSkinTypes([]);
    setSelectedCategories([]);
    setSelectedAttributes([]);
    setStep(0);
    setShowSkinTypeChecker(false);
    localStorage.removeItem('productFinderState');
  };

  const toggleWishlist = (product) => {
    setWishlist(prev => {
      const isProductInWishlist = prev.some(item => item.name === product.name);
      if (isProductInWishlist) {
        return prev.filter(item => item.name !== product.name);
      } else {
        return [...prev, product];
      }
    });
  };

  const isInWishlist = (product) => {
    return wishlist.some(item => item.name === product.name);
  };

  const handleSkinTypesDetermined = (determinedTypes) => {
    setSelectedSkinTypes(determinedTypes);
    setShowSkinTypeChecker(false);
    setStep(2); // Gehe direkt zur Kategorie-Auswahl
  };

  const renderHeader = () => {
    const wishlistProductIds = wishlist.map(product => product.id).join(',');
    
    return (
      <div className="flex flex-col mb-4">
        <div className="flex justify-between items-center mb-2">
          <Button
            variant="ghost"
            className="flex items-center text-button"
            onClick={() => setShowWishlist(!showWishlist)}
          >
            <Heart className={`mr-2 ${wishlist.length > 0 ? "fill-current text-red-500" : ""}`} />
            <span className="mr-2">Merkliste</span>
            <span>({wishlist.length})</span>
          </Button>
          {showWishlist && wishlist.length > 0 && (
            <a
              href={`https://www.juui.de/add-to-cart?product_ids=${wishlistProductIds}`}
              target="self"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline flex items-center text-button"
            >
              <ShoppingCart className="w-5 h-5 mr-2" />
              Merkliste in Warenkorb
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderSelectedItems = () => {
    return (
      <div className="flex flex-wrap gap-2 mb-4">
        {selectedSkinTypes.map((type, index) => (
          <span key={index} className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs md:text-sm flex items-center">
            {type}
            <Button
              variant="ghost"
              size="sm"
              className="ml-1 p-0 text-button"
              onClick={() => setSelectedSkinTypes(prev => prev.filter(t => t !== type))}
            >
              <X size={14} />
            </Button>
          </span>
        ))}
        {selectedCategories.map((cat, index) => (
          <span key={index} className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs md:text-sm flex items-center">
            {cat}
            <Button
              variant="ghost"
              size="sm"
              className="ml-1 p-0 text-button"
              onClick={() => setSelectedCategories(prev => prev.filter(c => c !== cat))}
            >
              <X size={14} />
            </Button>
          </span>
        ))}
        {selectedAttributes.map((attr, index) => (
          <span key={index} className="px-2 py-1 bg-purple-100 text-purple-800 rounded-full text-xs md:text-sm flex items-center">
            {attr}
            <Button
              variant="ghost"
              size="sm"
              className="ml-1 p-0 text-button"
              onClick={() => setSelectedAttributes(prev => prev.filter(a => a !== attr))}
            >
              <X size={14} />
            </Button>
          </span>
        ))}
      </div>
    );
  };

  const renderWishlist = () => {
    return (
      <div className="flex flex-col font-sans">
        <Card>
          <CardHeader>
            <CardTitle className="font-sans text-heading font-normal">Meine Merkliste</CardTitle>
          </CardHeader>
          <CardContent>
            {wishlist.length > 0 ? (
              <ul className="space-y-4 md:space-y-6">
                {wishlist.map((product, index) => (
                  <li key={index} className="bg-gray-100 p-4 md:p-6 rounded-lg shadow-md group relative">
                    <div className="flex flex-col md:flex-row items-start">
                      <img src={product.image} alt={product.name} className="w-full md:w-48 h-48 object-cover rounded-md mb-4 md:mb-0 md:mr-6" />
                      <div className="flex-grow">
                        <h3 className="text-lg md:text-xl">
                          <a href={product.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                            {product.name}
                          </a>
                        </h3>
                        <p className="text-sm md:text-md text-gray-600 mt-2">{product.categories.join(', ')} - {product.skinTypes.join(', ')}</p>
                        <div className="mt-2 md:mt-4">
                          <p className="text-sm md:text-md text-gray-500">{product.attributes.join(', ')}</p>
                          <p className="text-sm md:text-md text-gray-500 font-bold mt-2">{product.price ? `€${product.price}` : 'Preis nicht verfügbar'}</p>
                          <p className="text-sm md:text-md text-gray-500 mt-2">Verfügbar: {product.stock}</p>
                        </div>
                      </div>
                      <div className="absolute top-2 right-2 md:top-4 md:right-4 flex space-x-2">
                        <a
                          href={`https://www.juui.de/add-to-cart?product_ids=${product.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="ghost" className="text-button">
                            <ShoppingCart className="w-5 h-5 md:w-6 md:h-6" />
                          </Button>
                        </a>
                        <Button
                          variant="ghost"
                          onClick={() => toggleWishlist(product)}
                          className="text-button"
                        >
                          <X size={20} />
                        </Button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-center py-4 md:py-6 bg-yellow-100 text-yellow-800 rounded-lg text-lg md:text-xl">Ihre Merkliste ist leer.</p>
            )}
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderStepIcon = (step) => {
    return (
      <div className="relative">
        <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
          {step === 1 && <User className="h-6 w-6" />}
          {step === 2 && <Box className="h-6 w-6" />}
          {step === 3 && <Sliders className="h-6 w-6" />}
        </div>
        <div className="absolute -bottom-1 -right-1 bg-blue-500 rounded-full w-5 h-5 flex items-center justify-center text-white text-xs font-bold">
          {step}
        </div>
      </div>
    );
  };

  const renderStepContent = () => {
    switch(step) {
      case 0:
        return (
          <Card className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white font-sans">
            <CardHeader>
              <CardTitle className="font-sans text-heading font-normal">Willkommen beim Juui Produktfinder</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4 md:mb-6 text-lg md:text-xl">Laß uns die perfekten K-Beauty Produkte für dich finden!</p>
              <Button 
                onClick={() => setStep(1)} 
                className="bg-white hover:bg-gray-200 text-button px-4 md:px-6 py-2 md:py-3 start-button"
              >
                <span className="text-black">Los geht's</span>
              </Button>
            </CardContent>
          </Card>
        );
      case 1:
        if (showSkinTypeChecker) {
          return (
            <SkinTypeChecker 
              onSkinTypesDetermined={handleSkinTypesDetermined}
              onBack={() => setShowSkinTypeChecker(false)}
            />
          );
        }
        return (
          <Card className="font-sans">
            <CardHeader>
              <CardTitle className="font-sans text-heading font-normal flex items-center">
                {renderStepIcon(step)}
                <span className="ml-4 text-lg md:text-xl">Hauttyp auswählen</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              {renderSelectedItems()}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4">
                {allSkinTypes.map((type, index) => (
                  <Button
                    key={index}
                    variant="outline"
                    onClick={() => setSelectedSkinTypes(prev => 
                      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
                    )}
                    className={`justify-start h-auto py-2 md:py-3 text-button ${selectedSkinTypes.includes(type) ? 'bg-blue-100 text-blue-800' : ''}`}
                  >
                    {type}
                    {selectedSkinTypes.includes(type) && <X size={16} className="ml-2" />}
                  </Button>
                ))}
                <Button
                  variant="outline"
                  onClick={() => setShowSkinTypeChecker(true)}
                  className="justify-start h-auto py-2 md:py-3 text-button"
                >
                  Ich kenne meinen Hauttyp nicht
                </Button>
              </div>
            </CardContent>
          </Card>
        );
      case 2:
      case 3:
        return (
          <Card className="font-sans">
            <CardHeader>
              <CardTitle className="font-sans text-heading font-normal flex items-center">
                {renderStepIcon(step)}
                <span className="ml-4 text-lg md:text-xl">
                  {step === 2 ? "Kategorie auswählen" : "Eigenschaften bestimmen"}
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              {renderSelectedItems()}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4">
                {step === 2 && availableCategories.map((cat, index) => (
                  <Button
                    key={index}
                    variant="outline"
                    onClick={() => setSelectedCategories(prev => 
                      prev.includes(cat) ? prev.filter(c => c !== cat) : [...prev, cat]
                    )}
                    className={`justify-start h-auto py-2 md:py-3 text-button ${selectedCategories.includes(cat) ? 'bg-green-100 text-green-800' : ''}`}
                  >
                    {cat}
                    {selectedCategories.includes(cat) && <X size={16} className="ml-2" />}
                  </Button>
                ))}
                {step === 3 && availableAttributes.map((attr, index) => (
                  <Button
                    key={index}
                    variant="outline"
                    onClick={() => setSelectedAttributes(prev => 
                      prev.includes(attr) ? prev.filter(a => a !== attr) : [...prev, attr]
                    )}
                    className={`justify-start h-auto py-2 md:py-3 text-button ${selectedAttributes.includes(attr) ? 'bg-purple-100 text-purple-800' : ''}`}
                  >
                    {attr}
                    {selectedAttributes.includes(attr) && <X size={16} className="ml-2" />}
                  </Button>
                ))}
              </div>
            </CardContent>
          </Card>
        );
      case 4:
        return (
          <div className="flex flex-col font-sans">
            <Card>
              <CardHeader>
                <CardTitle className="font-sans text-heading font-normal">Deine K-Beauty Auswahl</CardTitle>
              </CardHeader>
              <CardContent>
                {renderSelectedItems()}
                {filteredProducts.length > 0 ? (
                  <ul ref={listRef} className="space-y-4 md:space-y-6">
                    {filteredProducts.map((product, index) => (
                      <li key={index} className="bg-gray-100 p-4 md:p-6 rounded-lg shadow-md group relative">
                        <div className="flex flex-col md:flex-row items-start">
                          <img src={product.image} alt={product.name} className="w-full md:w-48 h-48 object-cover rounded-md mb-4 md:mb-0 md:mr-6" />
                          <div className="flex-grow">
                            <h3 className="text-lg md:text-xl">
                              <a href={product.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                {product.name}
                              </a>
                            </h3>
                            <p className="text-sm md:text-md text-gray-600 mt-2">{product.categories.join(', ')} - {product.skinTypes.join(', ')}</p>
                            <div className="mt-2 md:mt-4">
                              <p className="text-sm md:text-md text-gray-500 group-hover:hidden">{product.attributes.join(', ')}</p>
                              <p className="text-sm md:text-md text-gray-500 group-hover:hidden font-bold mt-2">
                                {product.price ? `€${product.price}` : 'Preis nicht verfügbar'}
                              </p>
                              <p className="text-sm md:text-md text-gray-500 group-hover:hidden mt-2">
                                Verfügbar: {product.stock}
                              </p>
                              <p 
                                className="text-sm md:text-md text-gray-700 hidden group-hover:block" 
                                dangerouslySetInnerHTML={{ __html: getFirstTwoSentences(product.description) }}
                              />
                            </div>
                          </div>
                          <div className="absolute top-2 right-2 md:top-4 md:right-4 flex space-x-2">
                            <a
                              href={`https://www.juui.de/add-to-cart?product_ids=${product.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="ghost" className="text-button">
                                <ShoppingCart className="w-5 h-5 md:w-6 md:h-6" />
                              </Button>
                            </a>
                            <Button
                              variant="ghost"
                              onClick={() => toggleWishlist(product)}
                              className="text-button"
                            >
                              <Heart className={`w-5 h-5 md:w-6 md:h-6 ${isInWishlist(product) ? "fill-current text-red-500" : ""}`} />
                            </Button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-center py-4 md:py-6 bg-yellow-100 text-yellow-800 rounded-lg text-lg md:text-xl">
                    Leider konnten wir keine passenden Produkte finden. Versuchen Sie es mit anderen Kriterien.
                  </p>
                )}
              </CardContent>
            </Card>
          </div>
        );
    }
  };

  const renderNavButtons = () => {
    if (step > 0 && step < 4) {
      return (
        <div className="flex justify-between space-x-4 mt-4">
          <Button 
            onClick={() => setStep(step - 1)} 
            className="flex-1 text-white px-4 py-2"
          >
            {step === 1 ? 'Zurück' : 'Zurück zu ' + (step === 2 ? 'Hauttypen' : 'Kategorien')}
          </Button>
          <Button 
            onClick={() => setStep(step + 1)} 
            className="flex-1 text-white px-4 py-2"
          >
            {step === 3 ? 'Empfehlungen anzeigen' : 'Weiter zu ' + (step === 1 ? 'Kategorien' : 'Eigenschaften')}
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <div ref={containerRef} className="w-full md:w-[80%] max-w-7xl mx-auto py-4 font-sans px-4 md:px-0">
      {renderHeader()}
      <div>
        {loading ? (
          <p className="text-center py-4">Lade Produkte...</p>
        ) : error ? (
          <p className="text-center py-4 text-red-500">Fehler beim Laden der Produkte: {error}</p>
        ) : (
          <div>
            {showWishlist ? renderWishlist() : renderStepContent()}
            {step > 0 && step < 4 && !showSkinTypeChecker && renderNavButtons()}
          </div>
        )}
      </div>
      {(step === 4 || showWishlist) && (
        <div className="mt-4">
          <Button 
            onClick={() => {
              resetSelection();
              setShowWishlist(false);
            }} 
            className="w-full text-white py-2 md:py-3"
          >
            Neue Suche starten
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProductFinder;