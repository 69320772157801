import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import { CustomTooltip } from "../components/ui/tooltip";
import { InfoIcon } from 'lucide-react';
import { Modal } from "../components/Modal";

const SkinTypeChecker = ({ onSkinTypesDetermined, onBack }) => {
  const [skinTypeData, setSkinTypeData] = useState({});
  const [selectedAdjectives, setSelectedAdjectives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderedUniqueAdjectives, setOrderedUniqueAdjectives] = useState([]);
  const [adjectiveToSkinTypes, setAdjectiveToSkinTypes] = useState({});
  const [adjectiveDescriptions, setAdjectiveDescriptions] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [determinedTypes, setDeterminedTypes] = useState([]);

  useEffect(() => {
    const loadSkinTypeData = async () => {
      try {
        const [typeResponse, descriptionResponse] = await Promise.all([
          fetch('/hauttypencheck.txt'),
          fetch('/hauttypattribute.txt')
        ]);
        if (!typeResponse.ok || !descriptionResponse.ok) {
          throw new Error(`HTTP error! status: ${typeResponse.status}, ${descriptionResponse.status}`);
        }
        const typeText = await typeResponse.text();
        const descriptionText = await descriptionResponse.text();
        const { data, orderedUniqueAdj, adjToTypes } = parseSkinTypeData(typeText);
        const descriptions = parseAdjectiveDescriptions(descriptionText);
        setSkinTypeData(data);
        setOrderedUniqueAdjectives(orderedUniqueAdj);
        setAdjectiveToSkinTypes(adjToTypes);
        setAdjectiveDescriptions(descriptions);
        setLoading(false);
      } catch (error) {
        console.error('Error loading skin type data:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    loadSkinTypeData();
  }, []);

  const parseSkinTypeData = (text) => {
    const lines = text.split('\n');
    const data = {};
    let currentType = '';
    const adjToTypes = {};
    const uniqueAdj = new Set();
    const orderedUniqueAdj = [];

    lines.forEach(line => {
      line = line.trim();
      if (line === '') return;
      if (line.endsWith(':')) {
        currentType = line.slice(0, -1);
        data[currentType] = [];
      } else {
        data[currentType].push(line);
        if (!uniqueAdj.has(line)) {
          uniqueAdj.add(line);
          orderedUniqueAdj.push(line);
        }
        if (line in adjToTypes) {
          if (!adjToTypes[line].includes(currentType)) {
            adjToTypes[line].push(currentType);
          }
        } else {
          adjToTypes[line] = [currentType];
        }
      }
    });

    return { data, orderedUniqueAdj, adjToTypes };
  };

  const parseAdjectiveDescriptions = (text) => {
    const lines = text.split('\n');
    const descriptions = {};
    let currentAdjective = null;

    lines.forEach(line => {
      line = line.trim();
      if (line === '') {
        currentAdjective = null;
      } else if (currentAdjective === null) {
        currentAdjective = line;
        descriptions[currentAdjective] = '';
      } else {
        descriptions[currentAdjective] += (descriptions[currentAdjective] ? ' ' : '') + line;
      }
    });

    return descriptions;
  };

  const handleAdjectiveToggle = (adjective) => {
    setSelectedAdjectives(prev => 
      prev.includes(adjective) ? prev.filter(adj => adj !== adjective) : [...prev, adjective]
    );
  };

  const determineSkinTypes = () => {
    const skinTypeScores = Object.keys(skinTypeData).reduce((acc, type) => {
      acc[type] = 0;
      return acc;
    }, {});

    selectedAdjectives.forEach(adj => {
      adjectiveToSkinTypes[adj].forEach(type => {
        skinTypeScores[type]++;
      });
    });

    const totalSelectedAdjectives = selectedAdjectives.length;
    const normalizedScores = Object.keys(skinTypeScores).reduce((acc, type) => {
      acc[type] = skinTypeScores[type] / totalSelectedAdjectives;
      return acc;
    }, {});

    const sortedTypes = Object.entries(normalizedScores)
      .sort(([, a], [, b]) => b - a)
      .filter(([, score]) => score > 0);

    const threshold = 0.3;
    const types = sortedTypes
      .filter(([, score]) => score >= threshold)
      .map(([type, score]) => ({type, score}));

    if (types.length === 0 && sortedTypes.length > 0) {
      types.push({type: sortedTypes[0][0], score: sortedTypes[0][1]});
    }

    // Berechne die Gesamtsumme der Scores
    const totalScore = types.reduce((sum, {score}) => sum + score, 0);

    // Berechne die Prozente und runde sie
    const typesWithPercentages = types.map(({type, score}) => ({
      type,
      percentage: Math.round((score / totalScore) * 100)
    }));

    setDeterminedTypes(typesWithPercentages);
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    onSkinTypesDetermined(determinedTypes.map(({type}) => type));
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  if (loading) return <p>Lade Hauttypen-Daten...</p>;
  if (error) return <p>Fehler beim Laden der Hauttypen-Daten: {error}</p>;

  return (
    <>
      <Card className="font-sans">
        <CardHeader>
          <CardTitle className="font-sans text-heading font-normal">Bestimme deinen Hauttyp</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="mb-4">Wähle mindestens 5 Eigenschaften aus, die deine Haut am besten beschreiben:</p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {orderedUniqueAdjectives.map((adjective, index) => (
              <div key={index} className="flex items-center space-x-2">
                <Checkbox 
                  id={`adjective-${index}`} 
                  checked={selectedAdjectives.includes(adjective)}
                  onCheckedChange={() => handleAdjectiveToggle(adjective)}
                />
                <label 
                  htmlFor={`adjective-${index}`}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {adjective}
                </label>
                <CustomTooltip content={adjectiveDescriptions[adjective] || "Keine Beschreibung verfügbar"}>
                  <InfoIcon className="h-4 w-4 text-gray-500 cursor-help" />
                </CustomTooltip>
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-between">
            <Button onClick={onBack} variant="outline">
              Zurück
            </Button>
            <Button 
              onClick={determineSkinTypes} 
              disabled={selectedAdjectives.length < 5}
            >
              Hauttyp bestimmen
            </Button>
          </div>
        </CardContent>
      </Card>
      <Modal
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
      >
        <p>Basierend auf deinen Angaben haben wir folgende Hauttypen ermittelt:</p>
        <ul>
          {determinedTypes.map(({type, percentage}) => (
            <li key={type}>
              {type}: {percentage}%
            </li>
          ))}
        </ul>
        <p>Möchtest du diese Auswahl übernehmen?</p>
      </Modal>
    </>
  );
};

export default SkinTypeChecker;