import React from 'react';
import { Button } from "./ui/button";

export const StandaloneModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="mb-4">{children}</div>
        <div className="flex justify-end">
          <Button onClick={onClose}>Okay</Button>
        </div>
      </div>
    </div>
  );
};