import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import SkinTypeChecker from './SkinTypeChecker';
import { StandaloneModal } from './StandaloneModal.jsx';

const StandaloneSkinTypeChecker = () => {
  const [showChecker, setShowChecker] = useState(false);
  const [step, setStep] = useState(0);
  const [determinedTypes, setDeterminedTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const showCheckerParam = urlParams.get('showSkinTypeChecker');
    setShowChecker(showCheckerParam === 'true');
  }, []);

  const handleSkinTypesDetermined = (types) => {
    setDeterminedTypes(types);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setStep(0); // Zurück zum Startscreen
  };

  if (!showChecker) {
    return null;
  }

  const renderStepContent = () => {
    switch(step) {
      case 0:
        return (
          <Card className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white font-sans">
            <CardHeader>
              <CardTitle className="font-sans text-heading font-normal">Willkommen zum Skintype Checker</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4 md:mb-6 text-lg md:text-xl">Bestimme deinen Hauttyp um die besten K-Beauty Produkte für dich zu finden</p>
              <Button 
                onClick={() => setStep(1)} 
                className="bg-white hover:bg-gray-200 text-button px-4 md:px-6 py-2 md:py-3 start-button"
              >
                <span className="text-black">Los geht's</span>
              </Button>
            </CardContent>
          </Card>
        );
      case 1:
        return (
          <SkinTypeChecker 
            onSkinTypesDetermined={handleSkinTypesDetermined}
            onBack={() => setStep(0)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full md:w-[80%] max-w-7xl mx-auto py-4 font-sans px-4 md:px-0">
      {renderStepContent()}
      <StandaloneModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2 className="text-xl font-bold mb-4">Dein Hauttyp</h2>
        <p>Basierend auf deinen Angaben haben wir folgende Hauttypen ermittelt:</p>
        <ul className="list-disc list-inside my-4">
          {determinedTypes.map((type) => (
            <li key={type}>{type}</li>
          ))}
        </ul>
        <p>Mit diesem Wissen kannst du nun gezielt nach K-Beauty Produkten suchen, die für deinen Hauttyp geeignet sind.</p>
      </StandaloneModal>
    </div>
  );
};

export default StandaloneSkinTypeChecker;