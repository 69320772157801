import React, { useState, useEffect } from 'react';
import ProductFinder from './components/ProductFinder';
import StandaloneSkinTypeChecker from './components/StandaloneSkinTypeChecker';

function App() {
  const [showSkinTypeChecker, setShowSkinTypeChecker] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const showChecker = urlParams.get('showSkinTypeChecker');
    setShowSkinTypeChecker(showChecker === 'true');
  }, []);

  return (
    <div className="App">
      {showSkinTypeChecker ? <StandaloneSkinTypeChecker /> : <ProductFinder />}
    </div>
  );
}

export default App;