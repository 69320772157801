import React, { useState } from 'react';

export const CustomTooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-50 p-3 mt-2 text-sm text-white bg-gray-800 rounded-lg shadow-lg w-64 break-words left-1/2 transform -translate-x-1/2">
          {content}
        </div>
      )}
    </div>
  );
};
